<template>
  <a-modal v-model:visible="visible" width="40%" title="编辑店铺分组" :footer="null" :maskClosable="false" :destroyOnClose="true">
    <div>
        <p>已绑定的店铺</p>
        <a-select
            v-model:value="selected"
            style="width: 100%;"
            :allowClear="true"
            show-search
            mode="multiple"
            :disabled="useShopGroupIds==0"
            size="small"
            :filter-option="filterOption"
            @change="selectShop"
            :maxTagCount="20"
            :maxTagTextLength="20"
            placeholder="请选择你要绑定的店铺"
        >
          <a-select-option v-for="shop in shopGroup.ShopIds.data" :key="shop.shopId" :name="shop.shopName" :value="shop.shopId">
            {{shop.shopName}}
          </a-select-option>
        </a-select>
        <br><br>
        <p>选择分组</p>
        <a-select
            v-model:value="useShopGroupIds"
            style="width: 100%;"
            size="small"
            placeholder="选择分组"
            @change="selectGroup"
        >
        <a-select-option v-for="shop in shopGroup.group" :key="shop.id" :name="shop.name" :value="shop.id">
          {{shop.name}}{{'('+shop.data.length+'个店铺)'}}
        </a-select-option>
      </a-select>
        <br><br>
        <a-row :gutter="[20,20]">
          <a-col :span="14">
            <a-input v-model:value="groupName" placeholder="分组名称" size="small"></a-input>
          </a-col>
          <a-col :span="10" style="text-align: right">
            <a-button type="primary" @click="addGroup" size="small"><PlusOutlined></PlusOutlined>添加一个分组</a-button>&nbsp;&nbsp;
            <a-button type="primary" danger @click="deleteGroup" :disabled="useShopGroupIds==0" size="small">
              <DeleteOutlined></DeleteOutlined>删除选择分组</a-button>
          </a-col>
          <a-col :span="14">
            <a-input v-model:value="shopListCode" placeholder="请输入您的店铺共享码" size="small"></a-input>
          </a-col>
          <a-col :span="10" style="text-align: right">
            <a-button size="small" type="primary" @click="handleUseCode">
              <appstore-add-outlined />
              使用共享码
            </a-button>&nbsp;&nbsp;
            <a-button size="small" danger @click="handleCreateShare">
              <branches-outlined />
              创建共享码
            </a-button>
          </a-col>
        </a-row>
        <a-divider>

        </a-divider>
    </div>
  </a-modal>
</template>
<script>
import { defineComponent ,getCurrentInstance ,reactive ,ref} from 'vue'
import { PlusOutlined,BranchesOutlined ,AppstoreAddOutlined ,DeleteOutlined} from '@ant-design/icons-vue'

import {
  Modal,
  Select,
  SelectOption,
  Input,
  Row,
  Col,
  Button,
  message,
} from 'ant-design-vue'
import store from '@/store/index'
import {savaGroup} from "@/api/shopIds";
import {createShare,useCode} from "../../api/api";


export default defineComponent({
  name : "editGorup",
  components:{
    aModal : Modal,
    BranchesOutlined : BranchesOutlined,
    AppstoreAddOutlined : AppstoreAddOutlined,
    aSelect:Select,
    aSelectOption:SelectOption,
    aInput : Input,
    aRow : Row,
    aCol : Col,
    aButton : Button,
    PlusOutlined : PlusOutlined,
    DeleteOutlined : DeleteOutlined,
  },
  props: {
    shopGroup: {},
    onClose : Function
  },
  data(){
    return{
      visible :true,
      shopListCode: "",
      data : [],
      useShopGroupIds :0,
      selected : [],
      groupName : '',
    }
  },
  mounted() {
    this.selectGroup()
  },
  methods:{
    selectGroup(){
      this.shopGroup.group.forEach(shopIds=>{
        if (shopIds.id==this.useShopGroupIds){
          this.selected = []
          shopIds.data.forEach(shop=>{
            this.selected.push(shop.shopId)
          })
        }
      })
      console.log(this.shopGroup)
      console.log(this.shopGroup.ShopIds)
      console.log(this.shopGroup.data)
    },
    selectShop(){
      let shopIds = [];
      for (let selectedIndex = 0 ; selectedIndex<this.selected.length;selectedIndex++){
        for (let i = 0 ; i < this.shopGroup.ShopIds.data.length;i++){
          if (this.shopGroup.ShopIds.data[i].shopId==this.selected[selectedIndex]){
            shopIds.push({
              shopName : this.shopGroup.ShopIds.data[i].shopName,
              shopId : this.shopGroup.ShopIds.data[i].shopId
            })
          }
        }
      }

      for (let i = 0 ; i < this.shopGroup.group.length;i++){
        if (this.shopGroup.group[i].id ==this.useShopGroupIds){
          // eslint-disable-next-line vue/no-mutating-props
          this.shopGroup.group[i].data = shopIds
        }
      }
      this.savaGroup()
    },
    addGroup(){
      if (this.groupName!=""){
        let id = this.shopGroup.group.length
        // eslint-disable-next-line vue/no-mutating-props
        this.shopGroup.group.push({
          id : id,
          name : this.groupName,
          data : []
        })
        this.useShopGroupIds = id
        this.selectGroup()
        this.groupName = ""
        this.savaGroup()
      }else{
        message.error("分组名不能为空")
      }
    },
    savaGroup(){
      let tempGroup = [];
      for (let i = 0 ; i < this.shopGroup.group.length;i++){
        if (this.shopGroup.group[i].id !=0){
          tempGroup.push(this.shopGroup.group[i])
        }
      }
      savaGroup(tempGroup,false).then(res=>{
        if (res.success){
          message.success("保存成功")
        }else{
          message.error(res.msg)
        }
      })
    },
    deleteGroup(){
      const appContext = getCurrentInstance()
      const shopGroup = reactive(this.shopGroup)
      const  useShopGroupIds = ref(this.useShopGroupIds)
      Modal.confirm({
        title: '确认询问',
        content: '是否删除当前选中的分组?',
        okText: '确认',
        cancelText: '取消',
        appContext,
        onOk(data=shopGroup) {
          let tempGroup = [];
          for (let i = 0 ; i < data.group.length;i++){
            if (data.group[i].id == useShopGroupIds.value){
              data.group.splice(i,1)
            }
          }
          for (let i = 0 ; i < data.group.length;i++){
            if (data.group[i].id !=0){
              tempGroup.push(data.group[i])
            }
          }
          useShopGroupIds.value = 0
          savaGroup(tempGroup,true).then(res=>{
            if (res.success){
              window.location.reload()
              message.success("保存成功")
            }else{
              message.error(res.msg)
            }
          })
        },
      });
    },
    handleCreateShare(){
      let ids = null
      this.shopGroup.group.forEach(shopIds=>{
        if (shopIds.id===this.useShopGroupIds){
          ids = shopIds
        }
      })

      const appContext = getCurrentInstance()
      const shopListCode = this
      Modal.confirm({
        title: `询问`,
        content: `是否确认共享当前分组共${ids.data.length}个店铺共享给其他账号使用?`,
        appContext,
        onOk(data=shopListCode) {
          createShare(ids.id).then(res=>{
            if(res.success){
              message.success("生成共享码成功")
              console.log(data)
              data.shopListCode = res.data['code']
            }else{
              message.error(res.msg)
            }
          })
        },
      });
    },
    handleUseCode(){
      if (this.shopListCode){
        useCode(this.shopListCode).then(res=>{
          if (res.success){
            message.success("共享店铺成功,请刷新页面即可看到")
          }else{
            message.error(res.msg)
          }
        })
      }
    }
  },
  setup() {
    const filterOption = (input, option) => {
      return option.name.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    };
    return {
      store,filterOption
    };
  },
})
</script>
